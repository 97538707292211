import './Card.css'

export default () => (
    <div className="birthdayCard">
      <div className="cardFront">
        <h3 className="happy">Vse najboljše!</h3>
        <div className="balloons">
          <div className="balloonOne" />
          <div className="balloonTwo" />
          <div className="balloonThree" />
          <div className="balloonFour" />
        </div>
      </div>
      <div className="cardInside">
        <h3 className="back">Vse najboljše!</h3>
        <p>Draga Katja,</p>
        <p>
          Želim ti vse in le najboljše, veliko zdravja in ljubezni. Nič ne skrbi, če se staraš, saj se itak vsi.
        </p>
        <p>Ostani tako krasno kot si, pa lepo praznuj!</p>
        <p className="name">Matej</p>
      </div>
    </div>
  );
  