import React, { useState } from 'react';
import { Container, Content, Grid, Row, Col, Message, useToaster, ButtonToolbar, SelectPicker, Button, Steps, Panel, ButtonGroup, } from 'rsuite';
import ScratchOff from './scratch-off.js'
import { ReactFloatingBalloons } from "react-floating-balloons";
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import Card from './Card.js';
import Slika from './IMG_5478.jpg';
import PDF from './RD-kupon-Katja.pdf';
import 'rsuite/dist/rsuite.min.css';
import './App.css';

const Quiz = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [random, setRandom] = useState(Math.floor(Math.random() * (2 - 0 + 1)) + 0)
  const [questions] = useState([
    {
      text: 'Katero je glavo mesto Francije?',
      choices: ['Paris', 'London', 'Rome'],
      correctAnswer: 'Paris',
    },
    {
      text: 'Če v Pythonu napišemo "p, q, r = 10, 20, 30", ter nato zaženemo "print(p, q, r)", kaj nam bo program izpisalo?',
      choices: ['10 20', '10 20 30', 'Error: invalid syntax'],
      correctAnswer: '10 20 30',
    },
    {
      text: 'A string is immutable in Python? Every time when we modify the string, Python Always create a new String and assign a new string to that variable.',
      choices: ['true', 'false'],
      correctAnswer: 'true',
    },
    {
      text: 'Which is a valid variable name in Python?',
      choices: ['1_data', 'old-data', 'prince_in_$', '_output'],
      correctAnswer: '_output',
    },
    {
      text: 'What is a correct syntax to output "Hello World" in Python?',
      choices: ['p("Hello World"', 'print("Hello World")', 'echo("Hello World")', 'print("Živjo Svet")'],
      correctAnswer: 'print("Hello World")',
    },
    {
      text: 'How do you insert COMMENTS in Python code?',
      choices: ['#like this', '//like this', '/* ovako */'],
      correctAnswer: '#like this',
    },
    {
      text: 'Danes bom imela lep dan',
      choices: ['true', 'false'],
      correctAnswer: 'true',
    },
    // Add more questions as needed
  ]);

  const [step, setStep] = React.useState(0);
  const onChange = nextStep => {
    setStep(nextStep < 0 ? 0 : nextStep > 3 ? 3 : nextStep);
  };

  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);

  const toaster = useToaster();

  const wrongResponse = [
    "Incorrect. But don't worry, you're in good company with all the other wrong answers.",
    "Sorry, but that answer is more wrong than a left-handed scissors.",
    "Oh dear, that answer is so wrong it's almost impressive."
  ];

  const correntResponse = [
    "Congratulations! You must be a genius...or just really lucky.",
    "Ha! I can't believe it, you actually got it right",
    "You did it! I'm impressed...and a little surprised."
  ];


  const messageFalse = (
    <Message showIcon type="error" header="Error">
      {wrongResponse[random]}
    </Message>
  );
  const messageTrue = (
    <Message showIcon type="success" header="Success">
      {correntResponse[random]}
    </Message>
  );

  

  const handleAnswer = (answer) => {
    setRandom(Math.floor(Math.random() * (2 - 0 + 1)) + 0);
    if (answer === questions[currentQuestion].correctAnswer) {
      setScore(score + 1);
      setCurrentQuestion(currentQuestion + 1);
      toaster.push(messageTrue, 'topEnd' )
    }
    else {
      toaster.push(messageFalse, 'topEnd' )
    }
  };

  return (
    <div>
      <Container>
        <Grid>
          <h1 style={{textAlign: "center"}}>ROJSTNODNEVNI KVIZ</h1>
          <div>
            {step === 0 &&
            <Panel className="flex" header={`Korak: ${step + 1}`}>
              <Row>
                <Col>
                  <h4>Dobrodošli na rojstnodnevnem kvizu. Danes ima rojstni dan Katja. <br />Katera Katja se sprašujete? Podrgnite sliko in izvedeli boste!</h4>
                  <h5>PS: za optimalno izkušnjo prosim uporabite računalnik.</h5>
                  <p>Nato nadaljujte na naslednji korak.</p>
                </Col>
              </Row>
              <ScratchOff
                width="640"
                height="480"
                background={Slika}
              />
            </Panel>
            }
            {step === 1 &&
            <Panel className="flex" header={`Korak: ${step + 1}`}>
              <h4>Bravo, prav ste ugotovili.</h4>
              <h5>Ker se je pa za rojstnodnevno darilo potrebno malo potruditi, je sedaj na tem koraku kviz z nekaj uprašanji.</h5> <h5>Reši ga! Srečno</h5>
              {currentQuestion < questions.length ? (
                <div>
                  <hr />
                  <h5 style={{textAlign: "left"}}>{questions[currentQuestion].text}</h5>
                  {questions[currentQuestion].choices.map((choice) => (
                  <Button style={{marginRight: "1rem"}} color="violet" appearance="ghost" key={choice} onClick={() => handleAnswer(choice)}>
                    {choice}
                  </Button>
                  ))}
                </div>
              ) : (
                <div>
                  <p>Čestitam! Prišli ste do konca kviza in dosegli {score} točk od skupaj kar {questions.length}.</p>
                  <p>Lahko nadaljujete.</p>
                </div>
              )}
            </Panel>
            }
            {step === 2 &&
            <Panel header={`Korak: ${step + 1}`}>
              <h5>Skoraj na koncu ...</h5>
              <p>Poizkusi počiti vse balone! Nato lahko nadaljuješ</p>
              <ReactFloatingBalloons
                count={15}
                msgText="Yayy!!"
                colors={['yellow', 'green', 'blue', 'red', 'orange', 'purple']}
                popVolumeLevel={0}
              />
            </Panel>
            }
            {step === 3 &&
            <Panel header={`Korak: ${step + 1}`}>
              <h5>Si na koncu rojstnodnevnega kviza.</h5>
              <div  style={{display: "flex", justifyContent: "center"}}>
                <Card />
              </div>
              
              <h6>Za darilo pa dobiš naslednji kuponček:</h6>
              <a href={PDF}><Button size="lg" color="cyan" appearance="primary">
                <FileDownloadIcon /> Prenesi kupon
              </Button></a>
            </Panel>
            }
            <hr />
            <Steps current={step}>
              <Steps.Item title="Začetek" />
              <Steps.Item title="... prva naloga ..." />
              <Steps.Item title="... skoraj konec ..." />
              <Steps.Item title="... KONEC" />
            </Steps>
            <hr />
            
            <ButtonGroup>
              <Button onClick={onPrevious} disabled={step === 0}>
                Nenaprej
              </Button>
              <Button onClick={onNext} disabled={step === 3}>
                Nenazaj
              </Button>
            </ButtonGroup>
          </div>
        </Grid>
      </Container>
    </div>
  );
};

export default Quiz;